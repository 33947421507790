<template>
  <div class="column no-padding is-full">
    <div class="card">
      <header class="card-header has-background-primary">
        <div @click.stop="$router.go(-1)" class="card-header-icon">
          <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
        </div>
        <p class="card-header-title has-text-light">PENCAPAIAN NILAI</p>
      </header>
      <div class="card-content events-card">
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Angkatan</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ stase.angkatan }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Departemen</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ stase.sub_departemen.nama }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Periode</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ stase.mulai }} - {{ stase.hingga }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Koordinator</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="stase.preceptor">{{ stase.preceptor.nama }}</template>
          </div>
        </div>
        <div class="card-content table-card-content">
          <b-table striped hoverable :data="stase.dokumenstase_set" :mobile-cards="false">
            <b-table-column field="waktu" label="Waktu" v-slot="props">{{ props.row.updated }}</b-table-column>
            <b-table-column field="dok_detail" label="Detail" v-slot="props">
              <a
                :href="props.row.dok_detail"
                target="_blank"
                download
              >{{getFilename(props.row.dok_detail)}}</a>
            </b-table-column>
            <b-table-column field="dok_summary" label="Summary" v-slot="props">
              <a
                :href="props.row.dok_summary"
                target="_blank"
                download
              >{{getFilename(props.row.dok_summary)}}</a>
            </b-table-column>

            <template slot="empty">
              <div class="content has-text-grey has-text-centered">
                <p>Tidak ada lampiran nilai.</p>
              </div>
            </template>
          </b-table>
        </div>
        <penilaian-table v-if="isLoaded" :stase="stase"></penilaian-table>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      </div>
    </div>
  </div>
</template>


<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import Stase from "../models/pencNilai.js";

export default {
  name: "StaseDetail",
  props: ["title"],
  mixins: [onlineCheckMixin, fetchCreatedMixin, deleteMixin],
  data() {
    this.staseMdl = new Stase();
    this.objectMdl = this.staseMdl;
    return this.staseMdl.getObservables();
  },
  components: {
    PenilaianTable: () => import("@/apps/stase/components/PenilaianTable.vue"),
  },
  methods: {
    fetchData() {
      this.staseMdl.load(this.$route.params.id);
    },
    getFilename(url) {
      const arr = url.split("/");
      return decodeURI(arr[arr.length - 1]);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";
.del-btn {
  color: #00d1b2 !important;
}
</style>